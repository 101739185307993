@tailwind base;
@tailwind components;

@layer components {
  .header-item {
    @apply w-max p-1.5 rounded-lg hover:bg-neutral-800 transition-colors cursor-pointer font-medium text-base flex items-center gap-x-1 select-none
  }

  .active-header-item {
    @apply bg-neutral-800 text-neutral-100 cursor-default select-none
  }

  .primary-button {
    @apply py-2 rounded-lg bg-indigo-500/20 px-4 text-sm font-semibold tracking-wider flex items-center gap-x-1 hover:bg-indigo-600/60 w-max active:scale-90 transition-transform select-none
  }

  .active-primary-button {
    @apply bg-neutral-800 text-neutral-100 cursor-default hover:bg-neutral-800 flex items-center gap-x-1 select-none
  }
}

@tailwind utilities;